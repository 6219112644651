import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const FeaturedServicesSection = styled.section`
    background-repeat:no-repeat;
    background-size:cover;
    background: #ff3c1c;

    @media ${device.tablet} {

    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const MainHeading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`
    height:20px;
    width:100px;
`;


export const FeatureServicesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.mobileXL} {
        flex-direction:column;
    }
`;

export const FeaturedServicesSingle = styled.div`
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    border-right: 1px solid #ff7171;
    padding: 50px 20px;

    :last-child {
        border-right: 0px solid #fff;
    }

    h5{
        margin-bottom:5px;
        line-height:1;
        margin-bottom: 0px;
        text-align: center;
        color: #fff;
        @media ${device.tablet} {
            line-height:1;
        }
    }

    @media ${device.laptopM} {
        h5{
            font-size:17px;
        }
    }

    @media ${device.laptop} {
        padding: 30px 10px;
        h5{
            font-size:15px;
        }
    }
    @media ${device.mobileXL} {
        border:none;
    }
`;

export const FeaturedServicesImg = styled.img`
    width: auto;
    height: 50px;
    margin-bottom:20px;
`;
