import styled from 'styled-components';
import {SectionHeading} from '../../../components/Common/common.style';
import BackgroundImg from '../../../assets/home-2-images/services-section-banner.jpg'
import {device} from '../../../components/Common/device';

export const OurServicesSection = styled.section`
    padding:100px 0px 65px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const OurServicesHeading = styled(SectionHeading)`
    color:#fff;
    text-align: center;
`;

export const OurServicesSingleLayout = styled.div`
    margin-bottom:30px;
    display:flex;
    align-items:center;
    flex-direction:column;
`;

export const ImageHolder = styled.div`
    width:80px;
    flex-shrink:0;

    img{
        margin-bottom: 15px;
        height: 80px;
        width:80px;
    }
`;



export const TextLayout = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    h4{
        text-align:center;
        font-size:24px;
        line-height:24px;
        margin-bottom:20px;
        color:#fff;
    }
    p{
        color:#fff;
        text-align:center;
    }
    a{
        color: #fff;
        transition: all 0.3s;
        transition: all 0.3s;
        display:flex;
        align-items:center;
    }
    a:hover{
        color:#ef4227;
        transition: all 0.3s;
        text-decoration:none;
    }
    a svg{
        transition: all 0.3s;
        position: relative;
        width:17px;
        margin-left:10px;
    }
    a:hover svg{
        padding-left:4px;
        transition: all 0.3s;
    }
    a:hover svg path{
        fill:#ef4227;
    }
`;
