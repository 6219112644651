import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ClientSection = styled.section`
    padding:60px 0px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:40px 5px;
    }
`;

export const ClientLogoLayout = styled.div`
    display:flex;
    flex-wrap:wrap;
    /* align-items:center; */
    justify-content: center;
`;

export const ClientLogoHolder = styled.div`
    padding: 20px 30px;
    width:20%;

    @media ${device.laptop} {
        width:25%;
    }
    @media ${device.tablet} {
        width:35%;
        padding: 20px 20px;
        height:auto;
    }
    @media ${device.mobileL} {
        width:45%;
    }
`;

export const ClientLogo = styled.div`
    height: 50px;
    img{
        height: 50px;
    }
`;
