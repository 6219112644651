
import React from "react";

const ButtonPlay = () => (
    <svg version="1.1"
    	 id="svg2" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsCc="http://creativecommons.org/ns#"
         xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSvg="http://www.w3.org/2000/svg"
    	 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="25px" height="25px"
    	 viewBox="271.947 314.606 25 25" enableBackground="new 271.947 314.606 25 25" xmlSpace="preserve">
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,654.21333)">
    	<g id="g12" transform="scale(0.1)">
    		<path id="path14" fill="#FFFFFF" d="M2224.772,2456.832l-178.125,89.674c-1.443,0.725-3.237,0.725-4.695,0
    			c-1.443-0.725-2.351-2.073-2.351-3.53v-179.352c0-1.458,0.908-2.805,2.351-3.53c0.718-0.359,1.538-0.542,2.336-0.542
    			c0.82,0,1.626,0.176,2.358,0.542l178.125,89.678c1.436,0.732,2.329,2.08,2.329,3.53S2226.208,2456.107,2224.772,2456.832"/>
    	</g>
    </g>
    </svg>
)
export default ButtonPlay;