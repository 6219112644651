import React from 'react';
import {FeatureServicesWrapper,FeaturedServicesSection,FeaturedServicesSingle,FeaturedServicesImg
} from './featuredservices.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const FeaturedServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                homePage2Json{
                    FeaturedServices{
                        FeaturedServicesImg1
                        FeaturedServicesHeading1
                        FeaturedServicesImg2
                        FeaturedServicesHeading2
                        FeaturedServicesImg3
                        FeaturedServicesHeading3
                        FeaturedServicesImg4
                        FeaturedServicesHeading4
                    }
                }
            }
    `);
    const FeaturedServicesData = JSONData.homePage2Json.FeaturedServices;
        return (
        <FeaturedServicesSection id="FeaturedServicesSection">
            <Container>
                <Row>
                    <Col lg="12">
                        <FeatureServicesWrapper>
                            <FeaturedServicesSingle>
                                <FeaturedServicesImg
                                    src={FeaturedServicesData.FeaturedServicesImg1}
                                    alt=""
                                />
                                <h5>
                                {FeaturedServicesData.FeaturedServicesHeading1}
                                </h5>
                            </FeaturedServicesSingle>

                            <FeaturedServicesSingle>
                                <FeaturedServicesImg
                                    src={FeaturedServicesData.FeaturedServicesImg2}
                                    alt=""
                                />
                                <h5>
                                {FeaturedServicesData.FeaturedServicesHeading2}
                                </h5>
                            </FeaturedServicesSingle>

                            <FeaturedServicesSingle>
                                <FeaturedServicesImg
                                    src={FeaturedServicesData.FeaturedServicesImg3}
                                    alt=""
                                />
                                <h5>
                                {FeaturedServicesData.FeaturedServicesHeading3}
                                </h5>
                            </FeaturedServicesSingle>

                            <FeaturedServicesSingle>
                                <FeaturedServicesImg
                                    src={FeaturedServicesData.FeaturedServicesImg4}
                                    alt=""
                                />
                                <h5>
                                {FeaturedServicesData.FeaturedServicesHeading4}
                                </h5>
                            </FeaturedServicesSingle>
                        </FeatureServicesWrapper>
                    </Col>
                </Row>
            </Container>
        </FeaturedServicesSection>
    );
}

export default FeaturedServices;
