import styled from 'styled-components';
import {CommonbtnRound,SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import {Row} from 'react-bootstrap';

export const PricingSection = styled.div`
    padding:100px 0px;
    background:#f9fafc;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const PricingHeadingWrapper = styled.div`
    margin-bottom:0px;

    @media ${device.laptop} {
        margin-bottom:40px;
    }

    p{
        margin-bottom: 20px;
    }
`;

export const PricingHeading = styled(SectionHeading)`
`;
export const BestPatch = styled.div`
    background: #ff3c1c;
    transform: rotate(40deg);
    transform-origin: 0 0;
    position: absolute;
    top: -25px;
    right: -60px;
    width: 130px;
    h5{
        padding: 5px;
        color: #fff;
        line-height: 1;
        margin: 0px;
    }
`;
export const PricingRow = styled(Row)`
    align-items:center;
`;

export const PricingPlanCol = styled.div`
    flex-grow: 1;
    position: relative;
    flex-basis: 0;
    background: #fff;
    box-shadow: 0px 0px 10px 0px #d0d0d0;
    text-align: center;
    padding: 40px 30px;
    overflow: hidden;

    @media ${device.tablet} {
        margin:0px;
        margin-bottom:30px;
    }

    img{
        height: 40px;
        margin-bottom: 10px;
    }

    h6{
        color:#303030;
        margin-bottom:30px;
        text-align:center;
    }
    hr{
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        background: #ddd;
        height:1px;
    }
    ul{
        margin-bottom:0px;
        margin-left: 0px;
    }
    ul li{
        list-style: none;
        margin-bottom: 10px;
        font-size:15px;
        color:#545454;
        line-height:25px;
    }
`;

export const PricingPlanColCenter = styled.div`
    background:#5f27fa;
    border-radius: 10px;
    text-align:center;
    padding:60px 25px;
    border-top:3px solid #5f27fa;
    box-shadow: 0px 0px 10px 0px #d8d8d8;
    padding-top:70px;
    padding-bottom:70px;

    @media ${device.tablet} {
        margin:30px 0px;
    }
`;

export const PricingPlanCenterHeading = styled.h3`
    color:#fff;
    margin-bottom:30px;
`;

export const PricingPlanCenterPrice = styled.h2`
    color:#fff;
    margin-bottom:30px;
`;

export const PricingPlanCenterLine = styled.hr`
    background: #7e50ff;
`;

export const PricingPlanCenterText = styled.p`
    font-weight:500;
    margin-bottom:5px;
    color:#fff;
`;


export const PricingPlanPrice = styled.h2`
    margin-top: 30px;
    margin-bottom: 20px;
    color: #ff3c1c;
    line-height:1;
    sup{
        font-size: 14px;
        margin-right: 5px;
        vertical-align: super;
    }
`;

export const PricingSpan = styled.span`
    font-size: 20px;
    color: #545454;
`;

export const PricingPlanBtn = styled(CommonbtnRound)`
    margin-top: 20px;
    display:inline-flex;
    align-items:center;
    border-radius:25px;
`;

export const PricingPlanBtnCenter = styled(CommonbtnRound)`
    border:1px solid #fff;
    margin-top: 30px;
    background:none;

    :before{
        background:#fff;
    }

    :hover{
        color:#000;
    }
`;

export const PricingButton = styled(CommonbtnRound)`
    border-radius:25px;
`;
