import React from 'react';
import {Container} from 'react-bootstrap';
import {
    ClientSection,
    ClientLogoLayout,
    ClientLogoHolder,
    ClientLogo
} from './clients.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Clients = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homePage2Json{
            Clients{
                ClientLogoDetails{
                    ClientLogo{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
  `);
  const ClientsData = JSONData.homePage2Json.Clients;

    return(
        <ClientSection>
            <Container>
                <ClientLogoLayout>
                {
                    ClientsData.ClientLogoDetails.map((item,idx) => {
                    return <ClientLogoHolder>
                    <ClientLogo>
                        <GatsImg
                            fluid={item.ClientLogo.childImageSharp.fluid}
                            alt=""

                        />
                    </ClientLogo>
                    </ClientLogoHolder>
                    })
                }
                </ClientLogoLayout>
            </Container>
        </ClientSection>
    );
}

export default Clients;
