import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {CommonbtnRound} from '../../../components/Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {RightArrow} from '@styled-icons/boxicons-regular/RightArrow';
import SectionCircleImg from '../../../assets/DesignIcons/circle-pattern-1.png';
import SectionRingImg from '../../../assets/DesignIcons/circle-pattern-3.png';

export const AboutSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
    @media ${device.laptop} {
        flex-direction:column-reverse;
    }
`;

export const TextLayout = styled.div`
    ul{
        list-style:none;
        margin:0;
    }
    ul li{
        display:flex;
        margin-bottom: 10px;
    }
    ul li p{
        line-height:1;
        margin-bottom:0;
        margin-left:20px;
        line-height:25px;
    }
    @media ${device.laptop} {
        margin-bottom:40px;
    }
`;



export const ArrowImg = styled.img`

`;


export const ContactUsBtn = styled(CommonbtnRound)`
    margin-top:15px;
`;

export const ImageHolder = styled.div`
    padding-right:10px;

    :before {
        content: "";
        display: block;
        position: absolute;
        top: -60px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: -60px;
        z-index: -1;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }
    :after {
        content: "";
        display: block;
        position: absolute;
        bottom: -40px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        right: -15px;
        z-index: -1;
        background-repeat: no-repeat;
        background-image: url(${SectionRingImg});

        @media ${device.laptop} {
            right: 0px;
        }
    }
    @media ${device.laptop} {
        padding-right:0px;
    }
`;

export const ReadMoreBtn = styled(CommonbtnRound)`
    margin-top:15px;
    border-radius:25px;
`;

export const RightArrowIcon = styled(RightArrow)`
    color:#ef5350;
    height:20px;
    width:20px;
    flex-shrink:0;
    margin-top:5px;
`;
