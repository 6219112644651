import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../containers/HomePage2/Banner"
import FeaturedServices from "../containers/HomePage2/FeaturedServices"
import About from "../containers/HomePage2/About"
import Clients from "../containers/HomePage2/Clients"
import OurServices from "../containers/HomePage2/OurServices"
import Stats from "../containers/HomePage2/Stats"
import Testimonials from "../containers/HomePage2/Testimonials"
import Pricing from "../containers/HomePage2/Pricing"
import Blogs from "../containers/HomePage2/Blogs"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";

const HomePage2 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner/>
        <FeaturedServices/>
        <About/>
        <Clients/>
        <OurServices/>
        <Stats/>
        <Testimonials/>
        <Pricing/>
        <Blogs/>
        <Footer templateType="type2"/>
    </Layout>
)

export default HomePage2;