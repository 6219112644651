import React from 'react';
import {AboutSection,CustomRow,TextLayout,RightArrowIcon,ImageHolder,ReadMoreBtn
} from './about.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import ButtonArrow from "../../../components/Common/buttonarrow"

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                homePage2Json{
                    About{
                       Heading
                       Description1
                       Description2
                       ListText1
                       ListText2
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                       PlayBtn
                       ReadMoreBtn
                    }
                }
            }
    `);
    const AboutData = JSONData.homePage2Json.About;
        return (
        <AboutSection id="AboutContainer">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg
                                fluid={AboutData.Img.childImageSharp.fluid}
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <h2>
                            {AboutData.Heading}
                            </h2>
                            <p>
                            {AboutData.Description1}
                            </p>
                            <p>
                            {AboutData.Description2}
                            </p>
                            <ul>
                                <li>
                                    <RightArrowIcon />
                                    <p>{AboutData.ListText1}</p>
                                </li>
                                <li>
                                    <RightArrowIcon />
                                    <p>{AboutData.ListText2}</p>
                                </li>
                            </ul>
                            <ReadMoreBtn>
                            <span>{AboutData.ReadMoreBtn}</span>
                                <ButtonArrow/>
                            </ReadMoreBtn>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;
