import React from 'react';
import {PricingSection,PricingHeadingWrapper,
    PricingHeading,PricingPlanCol,PricingPlanPrice,PricingSpan,
    PricingPlanBtn,PricingButton,PricingRow,BestPatch
} from './pricing.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import ButtonArrow from "../../../components/Common/buttonarrow"

const Pricing = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            homePage2Json {
                Pricing {
                    PricingHeading
                    PricingSubHeading
                    PricingButton
                    PricingPlanCol{
                        PricingPlanHeading
                        PricingPlanImg
                        PricingPlanPrice
                        PricingPlanPriceSpan
                        PricingPlanDarkText
                        PricingPlanLightText
                        PricingPlanBtn
                    }
                }
            }
        }
    `);
    const PricingData = JSONData.homePage2Json.Pricing;

    return (
        <PricingSection id="pricingContainer">
            <Container>
                <PricingRow>
                    <Col lg="5">
                        <PricingHeadingWrapper>
                            <PricingHeading>
                                {PricingData.PricingHeading}
                            </PricingHeading>
                            <p>
                                {PricingData.PricingSubHeading}
                            </p>
                            <PricingButton>
                            <span>{PricingData.PricingButton}</span> <ButtonArrow/>
                            </PricingButton>
                        </PricingHeadingWrapper>
                    </Col>

                    <Col lg="7">
                        <Row>
                            <Col sm="6">
                                <PricingPlanCol>
                                    <BestPatch>
                                        <h5>Best</h5>
                                    </BestPatch>
                                    <img src={PricingData.PricingPlanCol[0].PricingPlanImg} alt=""/>
                                    <h4>{PricingData.PricingPlanCol[0].PricingPlanHeading}</h4>
                                    <hr/>
                                    <PricingPlanPrice><sup>$</sup>{PricingData.PricingPlanCol[0].PricingPlanPrice} <PricingSpan>/ {PricingData.PricingPlanCol[0].PricingPlanPriceSpan}</PricingSpan></PricingPlanPrice>
                                    <ul>
                                        <li>Lorem Ipsum is simply</li>
                                        <li>industry. Lorem Ipsum has</li>
                                        <li>standard dummy text ever</li>
                                        <li>unknown printer took a</li>
                                        <li>Lorem Ipsum is simply</li>
                                        <li>industry. Lorem Ipsum has</li>
                                        <li>standard dummy text ever</li>
                                    </ul>
                                    <PricingPlanBtn href="/"><span>{PricingData.PricingPlanCol[0].PricingPlanBtn}</span> <ButtonArrow/></PricingPlanBtn>
                                </PricingPlanCol>
                            </Col>
                            <Col sm="6">
                                <PricingPlanCol>
                                    <img src={PricingData.PricingPlanCol[1].PricingPlanImg} alt=""/>
                                    <h4>{PricingData.PricingPlanCol[1].PricingPlanHeading}</h4>
                                    <hr/>
                                    <PricingPlanPrice><sup>$</sup><span>{PricingData.PricingPlanCol[1].PricingPlanPrice}</span> <PricingSpan>/ {PricingData.PricingPlanCol[1].PricingPlanPriceSpan}</PricingSpan></PricingPlanPrice>
                                    <ul>
                                        <li>Lorem Ipsum is simply</li>
                                        <li>industry. Lorem Ipsum has</li>
                                        <li>standard dummy text ever</li>
                                        <li>unknown printer took a</li>
                                        <li>Lorem Ipsum is simply</li>
                                        <li>industry. Lorem Ipsum has</li>
                                        <li>standard dummy text ever</li>
                                    </ul>
                                    <PricingPlanBtn href="/"><span>{PricingData.PricingPlanCol[1].PricingPlanBtn}</span> <ButtonArrow/></PricingPlanBtn>
                                </PricingPlanCol>
                            </Col>
                        </Row>
                    </Col>
                </PricingRow>
            </Container>
        </PricingSection>
    );
}

export default Pricing;
