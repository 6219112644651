import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {
    BlogSection,
    HeadingLayout,
    BlogHeading,
    BlogLayout,
    BlogSingleLayout,
    BlogTitle,BlogImgCol,
    BlogText,
    ReadMoreBtn
} from './blogs.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import ButtonArrow from "../../../components/Common/buttonarrow"

const Blog = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homePage2Json{
            Blog{
                BlogHeading
                BlogDetails{
                    BlogImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    BlogTitle
                    BlogText
                    ReadMoreBtn
                }
            }
        }
    }
  `);
  const BlogData = JSONData.homePage2Json.Blog;

    return(
        <BlogSection>
            <Container>
                <HeadingLayout>
                    <BlogHeading>
                    {BlogData.BlogHeading}
                    </BlogHeading>
                </HeadingLayout>
                <BlogLayout>
                    <Row>
                        <Col md={4}>
                            <BlogSingleLayout>
                                <BlogImgCol>
                                    <figure>
                                        <GatsImg
                                            fluid={BlogData.BlogDetails[0].BlogImage.childImageSharp.fluid}
                                            alt=""
                                        />
                                    </figure>
                                    <span>April - 15, 2020</span>
                                </BlogImgCol>
                                <BlogTitle>{BlogData.BlogDetails[0].BlogTitle}</BlogTitle>
                                <BlogText>{BlogData.BlogDetails[0].BlogText}</BlogText>
                                <ReadMoreBtn>{BlogData.BlogDetails[0].ReadMoreBtn} <ButtonArrow/></ReadMoreBtn>
                            </BlogSingleLayout>
                        </Col>

                        <Col md={4}>
                            <BlogSingleLayout>
                                <BlogImgCol>
                                    <figure>
                                        <GatsImg
                                            fluid={BlogData.BlogDetails[1].BlogImage.childImageSharp.fluid}
                                            alt=""
                                        />
                                    </figure>
                                    <span>April - 15, 2020</span>
                                </BlogImgCol>
                                <BlogTitle>{BlogData.BlogDetails[1].BlogTitle}</BlogTitle>
                                <BlogText>{BlogData.BlogDetails[1].BlogText}</BlogText>
                                <ReadMoreBtn>{BlogData.BlogDetails[1].ReadMoreBtn} <ButtonArrow/></ReadMoreBtn>
                            </BlogSingleLayout>
                        </Col>

                        <Col md={4}>
                            <BlogSingleLayout>
                                <BlogImgCol>
                                    <figure>
                                        <GatsImg
                                            fluid={BlogData.BlogDetails[2].BlogImage.childImageSharp.fluid}
                                            alt=""
                                        />
                                    </figure>
                                    <span>April - 15, 2020</span>
                                </BlogImgCol>
                                <BlogTitle>{BlogData.BlogDetails[2].BlogTitle}</BlogTitle>
                                <BlogText>{BlogData.BlogDetails[2].BlogText}</BlogText>
                                <ReadMoreBtn>{BlogData.BlogDetails[2].ReadMoreBtn} <ButtonArrow/></ReadMoreBtn>
                            </BlogSingleLayout>
                        </Col>
                    </Row>
                </BlogLayout>
            </Container>
        </BlogSection>
    )
}

export default Blog;
