import React from 'react';
import {OurServicesSection,HeadingLayout,OurServicesHeading,OurServicesSingleLayout,
    ImageHolder,TextLayout
} from './ourservices.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import ButtonArrow from "../../../components/Common/buttonarrow"

const OurServices  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homePage2Json{
            OurServices{
                OurServicesHeading
                Details{
                    OurServicesImage
                    SubHeading
                    Content
                }
            }
        }
    }
  `);
  const OurServicesData = JSONData.homePage2Json.OurServices;
    return (
        <OurServicesSection>
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <OurServicesHeading>
                            {OurServicesData.OurServicesHeading}
                            </OurServicesHeading>
                        </HeadingLayout>
                    </Col>
               </Row>
               <Row>
               {
                    OurServicesData.Details.map((item,idx) => {
                    return <Col md={6} lg={4}>
                        <OurServicesSingleLayout>
                            <ImageHolder>
                            <img
                                src={item.OurServicesImage}
                                alt=""
                            />
                            </ImageHolder>
                            <TextLayout>
                                <h4>
                                {item.SubHeading}
                                </h4>
                                <p>
                                {item.Content}
                                </p>
                                <a href="/">Read More<ButtonArrow/></a>
                            </TextLayout>
                        </OurServicesSingleLayout>
                   </Col>
                    })
                }
               </Row>
            </Container>
        </OurServicesSection>
    );
}
export default OurServices;
