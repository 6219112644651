import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const StatsSection = styled.section`
    background-repeat:no-repeat;
    background-size:cover;
    padding:80px 0px 50px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:60px 5px 30px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const MainHeading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`
    height:20px;
    width:100px;
`;

export const StatsSingle = styled.div`
    text-align:center;
    margin-bottom:30px;

    :before {
        top: -30px;
        content: "";
        display: inline-block;
        width: 50px;
        height: 200px;
        background: #fff9f9;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }

    img{
        width: auto;
        height: 50px;
        margin-bottom:15px;
    }

    h2{
        margin-bottom:5px;
        line-height:1;

        @media ${device.tablet} {
            line-height:1;
        }
    }
    p{
        text-align:center;
        margin-bottom:0px;
    }
`;
