import styled from 'styled-components';
import {CommonbtnRound} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';

export const BannerSection = styled.section`
    min-height:100vh;
`;

export const BannerSliderWrapper = styled.div`

    .slick-list{
        line-height:0;
    }

    .slick-active{
        z-index:999;
    }
`;

export const BannerSliderLayout = styled.div`
    position:relative;

    // ANIMATIONS
    @-webkit-keyframes leaves {
        0% {
            -webkit-transform: scale(1.0);
        }
        100% {
            -webkit-transform: scale(1.2);
        }
    }

    @keyframes leaves {
        0% {
            transform: scale(1.0);
        }
        100% {
            transform: scale(1.2);
        }
    }
`;

export const BannerImageHolder = styled.div`
    position:relative;
    width:100%;
    min-height:100vh;
    overflow:hidden;

    .bannerImage{
        min-height:400px;
        height:100vh;
        -webkit-animation: leaves 10s ease-in-out infinite alternate;
        animation: leaves 10s ease-in-out infinite alternate;
    }
`;

export const BannerContents = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:100%;
    display:flex;
    align-items:center;
    line-height:1.5;
    max-width:750px;
    margin:50px auto 0px;

    @media ${device.laptop} {
        max-width:600px;
    }

    @media ${device.tablet} {
        max-width:500px;
        margin:30px auto 0px;
    }

    @media ${device.mobileXL} {
        max-width:400px;
    }
`;

export const BannerTextLayout = styled.div`
    margin:auto;
    padding:25px;
    text-align:center;
    h1{
        margin:0px auto 20px;
        margin-bottom:30px;
        color:#fff;
        padding: 0px 20px;

        @media ${device.mobileL}{
            font-size:24px;
            line-height:30px;
            margin-bottom:20px;
        }
    }
    p{
        color:#fff;
        margin-bottom:30px;

        @media ${device.mobileL}{
            margin-bottom:20px;
        }
    }
    @media ${device.laptop} {
        padding: 10px;
    }

    @media ${device.mobileXL} {
        h1{
            font-size:30px;
        }
    }
`;



export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;
    text-align:center;
    justify-content:center;

    @media ${device.tablet} {
        justify-content:center;
    }
    @media ${device.mobileL} {
        flex-direction:column;
    }
`;

export const MoreBtn = styled(CommonbtnRound)`
    border-radius: 25px;

    :hover{
        color:#000;
    }

    @media ${device.mobileXL} {
        padding:5px 15px;
    }
`;

export const PlayBtn = styled(CommonbtnRound)`
    margin-left: 20px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 25px;
    background:none;

    svg{
        height:13px;
        width:13px;
    }
    :before{
        background:#fff;
    }

    :hover{
        color:#000;
    }

    @media ${device.mobileXL} {
        padding:5px 15px;
    }

    @media ${device.mobileL} {
        margin-left:0px;
        margin-top:10px;
    }
`;

export const NextPrev = styled.div`
    height: 0px;
    text-align: center;

    @media ${device.tablet}{
        right:0;
        left:0;
    }
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;

    :focus{
        outline:none;
    }
`;

export const PrevImg = styled.img`
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding-bottom: 0px;
    border: none;
    background: none;
    outline: none;
    position: absolute;
    top: calc(50% - 15px);
    z-index: 99;
    background: rgba(255,255,255,0.1);
    left: 20px;
    border-radius: 100%;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.mobileL} {
        display:none;
    }
`;

export const NextImg = styled.img`
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding-bottom: 0px;
    border: none;
    background: none;
    outline: none;
    position: absolute;
    top: calc(50% - 15px);
    border-radius: 100%;
    z-index: 99;
    background: rgba(255,255,255,0.1);
    right: 20px;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.mobileL} {
        display:none;
    }
`;
