import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const BlogSection = styled.section`
    padding:100px 0px 95px;

    @media ${device.tablet} {
        padding:80px 5px 45px;
    }
`;

export const HeadingLayout = styled.div`
`;

export const BlogHeading = styled(SectionHeading)`
    margin-bottom:75px;
    color:#111;
    text-align: center;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const BlogLayout = styled.div`
    text-align:center;
`;

export const BlogSingleLayout = styled.div`

    :hover img{
        -webkit-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition:all .3s;
    }
    @media ${device.tablet} {
        margin-bottom:30px;
    }
`;

export const BlogTitle = styled.h4`
    font-size:26px;
    line-height:30px;
    margin-top:20px;
`;

export const BlogText = styled.p`
    text-align:center;
`;

export const ReadMoreBtn = styled.a`
    color: #ff3c1c;
    transition: all .3s;
    cursor:pointer;
    display:inline-flex;
    align-items:center;

    svg{
        transition: all .3s;
        width:17px;
        margin-left:10px;
        position:relative;
        top:1px;
    }
    svg path{
        fill: #ff3c1c;
    }
    :hover{
        text-decoration:none;
        color: #7d7d7d;
    }
    :hover svg path{
        text-decoration:none;
        fill: #7d7d7d;
    }
`;

export const BlogImgCol = styled.div`
    position: relative;
    margin-bottom: 20px;

    figure{
        position: relative;
        overflow: hidden;
    }
    span {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        text-align: center;
        background: rgba(0,0,0,.7);
        color: #fff;
        padding: 5px;
        max-width: 150px;
        margin: auto;
        font-size: 14px;
    }
    img{
        min-height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
        -webkit-transition: transform .3s ease-in-out !important;
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;
